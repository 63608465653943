import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, mobileHero, mobileHeroText } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="100VH"
          w="100%"
          image={`url(${mobileHero}) bottom / cover no-repeat`}
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
          column
          justifyBetween
          alignCenter
        >
          <CFView column justifyStart alignCenter mt="100px">
            <CFImage src={mobileHeroText} w="95%" alt="mobile hero text" />
            <CFView
              center
              br="20px"
              bc="white"
              bw="2px"
              pv="5px"
              ph="25px"
              zIndex={99}
              mv="5px"
            >
              <CFView h3 color="white" bold>
                SURREY LOCATION
              </CFView>
            </CFView>
            <CFView
              column
              center
              mt="15px"
              bg="rgba(0,0,0,.5)"
              pv="8px"
              ph="20px"
              raleway
              xBold
              h2
              color="#C60000"
              textCenter
            >
              NOW OFFERING BOTH PICK UP & DELIVERY !
            </CFView>
            <CFView pulsate mt="20px">
              <OrderPickupButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="700px"
          w="100%"
          image={`url(${hero}) bottom / cover no-repeat`}
          zIndex={90}
          column
          justifyStart
          alignCenter
          boxShadow="0 2px 2px rgba(0,0,0,.5)"
        >
          <CFImage
            src={heroText}
            w="53%"
            maxWidth="770px"
            mt="120px"
            alt="heroText"
          />
          <CFView
            center
            br="20px"
            bc="white"
            bw="2px"
            pv="5px"
            ph="25px"
            zIndex={99}
            mb="30px"
            mt="-5px"
          >
            <CFView h3 color="white" bold mb="2px">
              SURREY LOCATION
            </CFView>
          </CFView>
          <CFView
            row
            center
            mb="15px"
            bg="rgba(0,0,0,.5)"
            pv="8px"
            ph="20px"
            raleway
            xBold
            h2
            color="white"
          >
            NOW OFFERING BOTH PICK UP &
            <CFView color="red" h2 xBold ml="8px" raleway>
              DELIVERY !
            </CFView>
          </CFView>
          <CFView mt="5px" pulsate>
            <OrderPickupButton />
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
